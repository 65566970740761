import React from 'react'
import { isEmpty } from 'ramda'
import Layout from '../components/layout'
import Accordion from '../components/Accordion'
import { Heading } from '../components/Typography'
import ImageWithText from '../components/ImageWithText'
import ConsultantList from '../components/ConsultantList'
import UniversitiesList from '../components/UniversitiesList'
import CandidatesList from '../components/CandidatesList'
import MarkdownToHtml from '../components/MarkdownToHtml'
import DisqusComments from '../components/DisqusComments'
import { processEdges } from '../utils/layouts'

export default ({ pageContext, location, data }) => {
  const { category, html, frontmatter, slug } = pageContext
  if (!data.allMarkdownRemark || isEmpty(data.allMarkdownRemark)) return <p>No data available.</p>
  const { edges } = data.allMarkdownRemark

  const subCategoryList = processEdges(edges)

  return (
    <Layout {...{ location }}>
      <div className='container'>
        <div className='py-3'>
          <div className='row'>
            <div className='col-lg-4'>
              <Accordion parent={category} items={subCategoryList} slug={slug} />
            </div>
            <div className='col-lg-8'>
              <div className='layout-sub-category'>
                {frontmatter.header_image ? <ImageWithText {...{ frontmatter }} /> : <Heading>{frontmatter.title}</Heading>}
                <div dangerouslySetInnerHTML={{ __html: html || '' }} />
                {frontmatter.consultants ? <ConsultantList items={frontmatter.consultants} /> : null}
                {frontmatter.universities ? <UniversitiesList items={frontmatter.universities} /> : null}
                {frontmatter.candidates ? <CandidatesList items={frontmatter.candidates} /> : null}
                {frontmatter.bottom_markdown ? <MarkdownToHtml content={frontmatter.bottom_markdown} /> : null}
                {frontmatter.comments && <DisqusComments {...{ location }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
