import React from 'react'
import { Row, Col } from 'reactstrap'

const CandidateCard = ({ item }) => (
  <Col lg={3} md={6} sm={6}>
    <div className='tiles tiles-square'>
      <div className='tiles-image'>
        <img alt='' height='233' src={item.image} />
      </div>
      <div className='tiles-body'>
        <span className='tiles-label text-black'>{item.name}</span>
      </div>
      <a className='tiles-link' href={item.link} />
    </div>
  </Col>
)

export default ({ items }) => (
  <Row>
    {items
      ? items.map((item, i) => <CandidateCard key={i} item={item} />)
      : null}
  </Row>
)
