import React from 'react'

export default ({ location }) => {
  // eslint-disable-next-line
  const disqus_config = () => {
    this.page.url = location.pathname // Replace PAGE_URL with your page's canonical URL variable
    this.page.identifier = location.pathname // Replace PAGE_IDENTIFIER with your page's unique identifier variable
  }

  (function () { // DON'T EDIT BELOW THIS LINE
    if (typeof window !== 'undefined') {
      var d = document
      var s = d.createElement('script')
      s.src = 'https://pga-org.disqus.com/embed.js'
      s.setAttribute('data-timestamp', +new Date());
      (d.head || d.body).appendChild(s)
    }
  })()
  return (
    <div id='disqus_thread' />
  )
}
